import {
  ProjectConfiguration,
  stringifyEnvVariableAsJsonValueOrFail,
  stringifyEnvVariableOrFail,
} from '../index'

const prjConfiguration: ProjectConfiguration<'targa'> = {
  clientEnvResolver: (buildEnv, processEnv): ProcessEnv => {
    return {
      ANIA_MESSAGE_TIMEOUT: stringifyEnvVariableAsJsonValueOrFail(
        processEnv,
        'ANIA_MESSAGE_TIMEOUT'
      ),
      APP_HOST_PORT: stringifyEnvVariableOrFail(processEnv, 'APP_HOST_PORT'),
      APP_HOST_URL: stringifyEnvVariableOrFail(processEnv, 'APP_HOST_URL'),

      BACKEND_API_URL: stringifyEnvVariableOrFail(processEnv, 'BACKEND_API_URL'),
      BUILD_ENV: JSON.stringify(buildEnv),

      CHALLENGE_PROVIDER_URL: stringifyEnvVariableOrFail(processEnv, 'CHALLENGE_PROVIDER_URL'),
      COOKIE_PAGE_URL: stringifyEnvVariableOrFail(processEnv, 'COOKIE_PAGE_URL'),

      DATADOG_APPLICATION_ID: stringifyEnvVariableOrFail(processEnv, 'DATADOG_APPLICATION_ID'),
      DATADOG_CLIENT_TOKEN: stringifyEnvVariableOrFail(processEnv, 'DATADOG_CLIENT_TOKEN'),
      DATADOG_PROXY: stringifyEnvVariableOrFail(processEnv, 'DATADOG_PROXY'),
      DATADOG_SERVICE_NAME: stringifyEnvVariableOrFail(processEnv, 'DATADOG_SERVICE_NAME'),

      FLAGSHIP_API_KEY: stringifyEnvVariableOrFail(processEnv, 'FLAGSHIP_API_KEY'),
      FLAGSHIP_ENVIRONMENT_ID: stringifyEnvVariableOrFail(processEnv, 'FLAGSHIP_ENVIRONMENT_ID'),
      FULL_QUOTE_REDIRECT_URL: stringifyEnvVariableOrFail(processEnv, 'FULL_QUOTE_REDIRECT_URL'),

      GTM_ID: stringifyEnvVariableOrFail(processEnv, 'GTM_ID'),

      IS_BUILD_OPTIMIZED: stringifyEnvVariableAsJsonValueOrFail(processEnv, 'IS_BUILD_OPTIMIZED'),
      IS_CLAIMS_DATA_USAGE_ENABLED: stringifyEnvVariableAsJsonValueOrFail(
        processEnv,
        'IS_CLAIMS_DATA_USAGE_ENABLED'
      ),
      IS_CROSS_DOMAIN_COOKIE_ENABLED: stringifyEnvVariableAsJsonValueOrFail(
        processEnv,
        'IS_CROSS_DOMAIN_COOKIE_ENABLED'
      ),
      IS_DATADOG_ENABLED: stringifyEnvVariableAsJsonValueOrFail(processEnv, 'IS_DATADOG_ENABLED'),
      IS_DEV_ROUTER_ENABLED: stringifyEnvVariableAsJsonValueOrFail(
        processEnv,
        'IS_DEV_ROUTER_ENABLED'
      ),
      IS_DYNAMIC_FLAGS_FETCHING_ENABLED: stringifyEnvVariableAsJsonValueOrFail(
        processEnv,
        'IS_DYNAMIC_FLAGS_FETCHING_ENABLED'
      ),
      IS_ELM_BODY_PATCH_ENABLED: stringifyEnvVariableAsJsonValueOrFail(
        processEnv,
        'IS_ELM_BODY_PATCH_ENABLED'
      ),
      IS_FLAG_CHANGER_ENABLED: stringifyEnvVariableAsJsonValueOrFail(
        processEnv,
        'IS_FLAG_CHANGER_ENABLED'
      ),
      IS_PRIVATE_LOG_ENVIRONMENT: stringifyEnvVariableAsJsonValueOrFail(
        processEnv,
        'IS_PRIVATE_LOG_ENVIRONMENT'
      ),

      LOGIN_PAGE_URL: stringifyEnvVariableOrFail(processEnv, 'LOGIN_PAGE_URL'),

      PRIMA_PUBLIC_HOST: stringifyEnvVariableOrFail(processEnv, 'PRIMA_PUBLIC_HOST'),

      VERSION: stringifyEnvVariableOrFail(processEnv, 'VERSION'),
    }
  },
  transpilerConfiguration: {
    manifest: {
      appDescription:
        "Seleziona la polizza di cui hai bisogno, calcola un preventivo online e completa l'acquisto in pochi minuti su Prima.",
      appName: 'Prima',
      lang: 'it-IT',
    },
    preRenderedRoutes: ['targa', '404', 'targa/societa'],
    robotsTxt: {
      policy: [
        {
          allow: '/',
          userAgent: '*',
        },
      ],
    },
    startUrl: 'targa',
  },
}

/**
 * Node.js Env redeclaration. Is used by FE code too for type hints. Since is not declared
 * as an importable type we redeclare it entirely instead of extending the package type with TZ field
 *
 * This Env object is shared between webpack and the transpiled client through typescript global declarations
 * In order to reduce risk it must be Json stringified, and is "type" checked at build time
 *
 * if you need one of these values during webpack phase ***REMEMBER TO JSON PARSE THEM***
 */
export interface ProcessEnv extends NodeJS.Dict<string> {
  APP_HOST_PORT: string
  APP_HOST_URL: string

  BACKEND_API_URL: string
  BUILD_ENV: string

  CHALLENGE_PROVIDER_URL: string
  COOKIE_PAGE_URL: string

  DATADOG_APPLICATION_ID: string
  DATADOG_CLIENT_TOKEN: string
  DATADOG_PROXY: string
  DATADOG_SERVICE_NAME: string

  FLAGSHIP_API_KEY: string
  FLAGSHIP_ENVIRONMENT_ID: string
  FULL_QUOTE_REDIRECT_URL: string

  GTM_ID: string

  IS_BUILD_OPTIMIZED: string
  IS_CLAIMS_DATA_USAGE_ENABLED: string
  IS_CROSS_DOMAIN_COOKIE_ENABLED: string
  IS_DATADOG_ENABLED: string
  IS_DEV_ROUTER_ENABLED: string
  IS_DYNAMIC_FLAGS_FETCHING_ENABLED: string
  IS_ELM_BODY_PATCH_ENABLED: string
  IS_PRIVATE_LOG_ENVIRONMENT: string

  LOGIN_PAGE_URL: string

  PRIMA_PUBLIC_HOST: string

  TZ?: string
  VERSION: string
}
export default prjConfiguration
