import { prettyError } from '../cli/lib'
import formMotorEsConfiguration from './form-motor-es/Configuration'
import formMotorItConfiguration from './form-motor-it/Configuration'

/**
 * Gets given keyed value from process.env OR breaks the whole build.
 * It uses json stringify to ensure string interpolation.
 * It can be used ONLY for string types (the 99% of your cases)
 *
 * @param processEnv - processEnv blob
 * @param key - key of process.env obj
 * @throws Error if not found
 * @returns value (as string)
 */
export function stringifyEnvVariableOrFail(processEnv: NodeJS.Dict<string>, key: string): string {
  const val = processEnv[key]
  if (typeof val == 'undefined') {
    prettyError(`Expected ${key} ENV variable is undefined`)

    throw new Error('Value not parsable')
  } else {
    return JSON.stringify(val)
  }
}

/**
 * Gets given keyed value from process.env OR breaks the whole build.
 * It reads the string written in the env as json value by default (useful for boolean flags)
 *
 * @param processEnv - processEnv blob
 * @param key - key of process.env obj
 * @throws Error if not found
 * @returns value (as string)
 */
export function stringifyEnvVariableAsJsonValueOrFail(
  processEnv: NodeJS.Dict<string>,
  key: string
): string {
  const val = processEnv[key]

  if (val == undefined) {
    prettyError(`Expected ${key} ENV variable is undefined`)

    throw new Error('Value not parsable')
  } else {
    return JSON.stringify(JSON.parse(val))
  }
}

/**
 * Parses a process env variable into a json type
 *
 * @param processEnv - process env param
 * @param key - key to parse
 * @throws Error if not found or type mismatch
 *
 * @returns the typed value as a bool if success
 */
export function parseBooleanVariableOrFail(
  processEnv: { [key: string]: boolean | string | undefined },
  key: string
): boolean {
  const val = processEnv[key]
  if (typeof val == 'boolean') return val
  else if (typeof val == 'string' && typeof JSON.parse(val) == 'boolean') return JSON.parse(val)
  else throw new Error(`Value ${key} not parsable: ${processEnv[key]}`)
}

/**
 * Parses a process env variable into a json type
 *
 * @param processEnv - process env param
 * @param key - key to parse
 * @throws Error if not found or type mismatch
 *
 * @returns the typed value as a string if success
 */
export function parseStringVariableOrFail(
  processEnv: { [key: string]: boolean | string | undefined },
  key: string
): string {
  const val = processEnv[key]
  if (typeof val == 'string') return val
  else throw new Error(`Value ${key} not parsable: ${processEnv[key]}`)
}

/**
 * Parses a process env variable into a json type
 *
 * @param processEnv - process env param
 * @param key - key to parse
 * @throws Error if not found or type mismatch
 *
 * @returns the typed value as a number if success
 */
export function parseNumberVariableOrFail(
  processEnv: { [key: string]: boolean | string | undefined },
  key: string
): number {
  const val = processEnv[key]
  if (typeof val == 'string' && typeof JSON.parse(val) == 'number') return JSON.parse(val)
  else throw new Error(`Value ${key} not parsable: ${processEnv[key]}`)
}

export type ProjectConfiguration<OpenPath extends string> = {
  clientEnvResolver: (
    buildEnvironment: BuildEnvironment,
    processEnv: NodeJS.Dict<string>
  ) => NodeJS.Dict<string>
  transpilerConfiguration: TranspilerConfiguration<OpenPath>
}

type TranspilerConfiguration<OpenPath extends string> = {
  manifest: {
    appDescription: string
    appName: string
    lang: string
  }
  preRenderedRoutes: [OpenPath, ...string[]]
  robotsTxt: {
    host?: string
    policy: RobotsTxtPolicyEntry[]
    sitemap?: string
  }
  startUrl: OpenPath
}

type RobotsTxtPolicyEntry =
  | {
      allow: string | string[]
      cleanParam?: string
      crawlDelay?: number
      disallow?: string | string[]
      userAgent: string
    }
  | {
      allow?: string | string[]
      cleanParam?: string
      crawlDelay?: number
      disallow: string | string[]
      userAgent: string
    }

export const projects: Map<string, ProjectConfiguration<string>> = new Map(
  Object.entries({
    'form-motor-es': formMotorEsConfiguration,
    'form-motor-it': formMotorItConfiguration,
  })
)

export enum BuildEnvironment {
  ci = 'ci',
  development = 'development',
  production = 'production',
  staging = 'staging',
}
